<template>
  <b-overlay :show="isLoading" rounded="sm">
    <div class="card mt-2">
      <div class="card-body">
        <h3 class="text-primary text-center">Edit Vendor Aggrement</h3>
        <hr />
        <div class="row">
          <div class="col-sm-3"></div>
          <div class="col-sm-6">
            <div class="form-group">
              <label for="organization"
                >Vendor<span class="text-danger">*</span> :</label
              >
              <multiselect
                v-model="vendor"
                label="name"
                track-by="name"
                placeholder="type to find vendor..."
                open-direction="bottom"
                :options="items_vendor"
                :searchable="true"
                :loading="isLoadingSearch"
                :close-on-select="true"
                @search-change="findVendorName"
                @select="selectVendor"
              >
                <span slot="noResult">
                  Oops! No elements found. Consider changing the search query.
                </span>
              </multiselect>
            </div>

            <div class="form-group">
              <label for="paymentCurrency"
                >Payment Currency<span class="text-danger">*</span> :</label
              >
              <b-form-group label-for="paymentCurrency">
                <b-form-select
                  id="paymentCurrency"
                  v-model="payment_currency"
                  :options="payment_currency_options"
                  required
                ></b-form-select>
              </b-form-group>
            </div>

            <div class="form-group">
              <label for="organization"
                >Revenue Share<span class="text-danger">*</span> :</label
              >
              <multiselect
                v-model="revenue"
                label="name"
                track-by="name"
                placeholder="type to find name of revenue share..."
                open-direction="bottom"
                :options="items_revenue"
                :searchable="true"
                :loading="isLoadingSearchRevenue"
                :close-on-select="true"
                @search-change="findRevenue"
                @select="selectRevenue"
              >
                <span slot="noResult">
                  Oops! No elements found. Consider changing the search query.
                </span>
              </multiselect>
            </div>

            <b-form-group label="Valid From* :">
              <b-form-datepicker
                today-button
                v-model="valid_from_date"
              ></b-form-datepicker>
            </b-form-group>

            <b-form-group label="Valid To* :">
              <b-form-datepicker
                today-button
                v-model="valid_to_date"
              ></b-form-datepicker>
            </b-form-group>

            <div class="form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="is_active"
                v-model="isActive"
              />
              <label class="form-check-label" for="is_active">Is Active</label>
            </div>

            <br />
            <div class="form-group">
              <label for="organization"
                >Default Fixed Amount :
                <span class="font-weight-bold">{{
                  default_fixed != null ? default_fixed : '-'
                }}</span></label
              >
            </div>
            <div class="form-group">
              <label for="organization"
                >Default Percentage Amount :
                <span class="font-weight-bold">{{
                  default_percentage != null ? default_percentage : '-'
                }}</span></label
              >
            </div>

            <br /><br />
            <button
              type="submit"
              class="btn btn-primary btn-block"
              @click="updateVendorAggrement()"
            >
              <i class="fa fa-pencil"></i>
              Update Vendor Aggrement
            </button>
          </div>
          <div class="col-sm-3"></div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>
<script>
import moment from 'moment';
import { mapActions, mapState } from 'vuex';
import constant from '../../store/constant';
const paymentCurrency = Object.entries(constant.PUBLISHER_PAYMENT_CURRENCY).map(
  (item) => {
    return {
      text: item[0],
      value: item[1],
    };
  },
);
export default {
  name: 'VendorAggrementCreate',
  data() {
    return {
      valid_to_date: null,
      valid_from_date: null,
      vendor: [],
      items_vendor: [],
      isLoadingSearch: false,
      revenue: [],
      items_revenue: [],
      isLoadingSearchRevenue: false,
      default_fixed: null,
      default_percentage: null,
      isActive: true,
      payment_currency: null,
      payment_currency_options: [
        { value: null, text: 'Select Payment Currency' },
        ...paymentCurrency,
      ],
    };
  },
  watch: {
    item: function() {
      if (!Object.keys(this.item).length) return;
      this.getVendorRevenue();
    },
    successMessage: function() {
      if (!this.successMessage) return;
      this.alertMsg(this.successMessage, 'success');
      this.$router.push('/vendor-aggrements');
    },
    errorMessage: function() {
      if (!this.errorMessage) return;
      this.alertMsg(this.errorMessage, 'error');
    },
    isError: function() {
      if (!this.isError) return;
      this.alertMsg('Ops. Something went wrong !', 'error');
    },
    itemsRevenueShares: function() {
      if (!Object.keys(this.itemsRevenueShares).length) return;
      this.items_revenue = this.itemsRevenueShares;
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Update Vendor Aggrement | Content Management System Ebooks Gramedia.com';
      },
    },
  },
  computed: {
    ...mapState({
      item: (state) => state.vendor_revenue.item,
      isLoading: (state) => state.vendor_revenue.isLoading,
      isError: (state) => state.vendor_revenue.isError,
      successMessage: (state) => state.vendor_revenue.successMessage,
      errorMessage: (state) => state.vendor_revenue.errorMessage,
      itemsRevenueShares: (state) => state.revenueshares.items,
    }),
    buttonLabel() {
      return this.showPassword ? 'Show' : 'Hide';
    },
  },
  created: function() {
    const revenueID = this.$route.params.id;
    this.fetchVendorAggrementById({ id: revenueID });
    this.actionGetRevenueShares();
  },
  methods: {
    ...mapActions('vendors', ['findVendor']),
    ...mapActions('vendorScoopint', ['findVendorScoopintByID']),
    ...mapActions('revenueshares', ['fetchAllRevenueShares']),
    ...mapActions('vendor_revenue', [
      'findVendorRevenue',
      'findVendorRevenueByID',
      'editVendorRevenue',
      'fetchVendorAggrementById',
    ]),

    getVendorRevenue() {
      const data = this.item;
      this.vendor = data.vendor;
      this.revenue = data.revenue_share;
      this.valid_to_date = data.valid_to;
      this.payment_currency = data.vendor.payment_currency
      this.valid_from_date = moment(data.valid_from).format('YYYY-MM-DD');
      this.isActive = data.is_active;
      this.default_fixed = data.revenue_share.default_fixed_amount;
      this.default_percentage = data.revenue_share.default_percentage_amount;
    },

    findVendorName(keyword) {
      if (keyword.length > 2) {
        this.isLoadingSearch = true;
        let payload = {
          q: keyword,
        };
        this.findVendor(payload)
          .then((resp) => {
            this.items_vendor = resp.data.data;
            this.isLoadingSearch = false;
          })
          .catch((err) => {
            this.isLoadingSearch = false;
            console.log(err);
          });
      }
    },

    findRevenue(keyword) {
      if (keyword.length > 2) {
        this.isLoadingSearchRevenue = true;
        let payload = {
          q: keyword,
        };
        this.findVendorRevenue(payload)
          .then((resp) => {
            this.items_revenue = resp.data.data;
            this.isLoadingSearchRevenue = false;
          })
          .catch((err) => {
            this.isLoadingSearchRevenue = false;
            console.log(err);
          });
      }
    },

    selectRevenue(data) {
      this.findVendorRevenueByID(data).then((res) => {
        const revenue = res.data.data;
        this.default_fixed = revenue.default_fixed_amount;
        this.default_percentage = revenue.default_percentage_amount;
      });
    },

    selectVendor(data) {
      this.findVendorScoopintByID({ id: data?.id }).then((res) => {
        this.payment_currency = res?.payment_currency;
      });
    },

    updateVendorAggrement() {
      const revenueID = this.$route.params.id;
      const payload = {
        id: revenueID,
        vendor_id: this.vendor.id,
        revenue_id: this.revenue.id,
        valid_to: this.valid_to_date,
        valid_from: this.valid_from_date,
        is_active: this.isActive,
        payment_currency: this.payment_currency,
      };

      this.editVendorRevenue(payload);
    },

    actionGetRevenueShares() {
      const payload = {
        status: true,
      };
      this.fetchAllRevenueShares(payload);
    },

    alertMsg(msg, type) {
      this.$swal({
        icon: type,
        toast: true,
        position: 'top-end',
        title: msg,
        timer: 10000,
        text: this.message,
      });
    },
  },
};
</script>
